import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SnackBarService } from '../snackBar-services/snack-bar.service';
@Injectable({
  providedIn: 'root',
})
export class AutoFileDownloadService {
  constructor(private readonly snackBarService: SnackBarService) {}
  autoFileDownload(data: any) {
    if (data?.statuscode === 200) {
      const reportUrl = `${environment.reportUrl}${data.clientName}/${data.uuid}${data.response}`;
      const link = document.createElement('a');
      link.href = reportUrl; // Set the URL
      link.download = reportUrl.split('/').pop(); // Filename from the URL
      link.style.display = 'none'; // Hide the link element
      document.body.appendChild(link); // Append it to the DOM
      link.click(); // Trigger the download
      document.body.removeChild(link);
      this.snackBarService.showSuccess(
        'Report successfully generated',
        'You report is being generated automatically on your desktop and will be available shortly'
      );
    } else {
      console.log('report generation response is not ok ');
    }
  }
}
